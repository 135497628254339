import { media, Icon, useI18n, color } from "@sencrop/ui-components";
import { graphql } from "gatsby";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { blockCss, containerCss, contentCss } from "../helpers/style";
import EventContainer from "../containers/EventContainer";
import ImageElement from "../components/ImageElement";
import { BAR_SIZE } from "../containers/Navigation";

const SectionEvents = ({
  events,
  visible,
  decorationImage,
  additionalLogo,
  index,
}: GatsbyTypes.SectionEventsFragmentFragment & { index: number }) => {
  const [open, setOpen] = useState(true);
  const { t } = useI18n();

  if (!visible) return null;
  const withMargin = open && index === 0;

  return (
    <Background withMargin={withMargin}>
      {open && events && (
        <EventsBanner>
          <Wrapper>
            <EventsWrapper>
              {decorationImage && (
                <ImageContainer>
                  <ImageElement
                    gatsbyImageData={decorationImage.gatsbyImageData}
                    alt={decorationImage.title}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </ImageContainer>
              )}
              {additionalLogo && (
                <LogoContainer>
                  <ImageElement
                    gatsbyImageData={additionalLogo.gatsbyImageData}
                    alt={additionalLogo.title}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </LogoContainer>
              )}
              {events &&
                (events || []).map((event, eventIndex) => (
                  <EventContainer
                    key={eventIndex}
                    event={event}
                    multiple={events.length > 1}
                    position={index === 0 ? "first" : "second"}
                  />
                ))}
            </EventsWrapper>
          </Wrapper>
          <CloseIcon
            onClick={() => setOpen(false)}
            role="button"
            tabIndex={0}
            aria-label={t("section_events.close.aria_label")}
          >
            <IconContainer>
              <Icon name="close" color="light" />
            </IconContainer>
          </CloseIcon>
        </EventsBanner>
      )}
    </Background>
  );
};

export default SectionEvents;

export const fragment = graphql`
  fragment SectionEventsFragment on ContentfulSectionEvents {
    visible
    events {
      ...LinkFragment
    }
    decorationImage {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    additionalLogo {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
  }
`;

type BackgroundProps = {
  withMargin: boolean;
};

const Background = styled.div<BackgroundProps>`
  z-index: 2;
  position: relative;
  background: linear-gradient(84.75deg, #11226a 70%, #436cca 100%);
  ${(props) =>
    props.withMargin &&
    css`
      & {
        top: ${BAR_SIZE.mobile}px;
        :before {
          content: "";
          height: ${BAR_SIZE.mobile}px;
          top: -${BAR_SIZE.mobile}px;
          position: absolute;
          width: 100%;
          left: 0;
          background: ${color("background-primary:10")};
        }
        ${media.greaterThan("desktop")`
        top: ${BAR_SIZE.desktop}px;
        :before {
          height: ${BAR_SIZE.desktop}px;
          top: -${BAR_SIZE.desktop}px;
        }
      `};
      }
    `}
`;

const Wrapper = styled.div`
  ${containerCss}
  ${blockCss}
  ${contentCss}
  padding: 5px 0px;
  ${media.greaterThan("tablet")`
    padding: 0px;
  `}
`;

const EventsWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding-left: 32%;
  padding-right: 10px;
  ${media.lessThan("tablet")`
  padding-left: 25%;
  `}
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: -300px;
  width: 100%;
  height: 60px;
  img {
    object-fit: contain !important;
  }
  ${media.lessThan("tablet")`
  img {
    object-fit: cover !important;
  }
  left: -38%;
  height: 69px;
  `};
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: -240px;
  width: 100%;
  height: 35px;
  ${media.lessThan("tablet")`
  display: none;
  `};
  img {
    object-fit: contain !important;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  ${media.greaterThan("tablet")`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 1rem;
  `}
  ${media.lessThan("tablet")`
  display: none;
  `}
`;

const EventsBanner = styled.div`
  display: flex;
  ${media.greaterThan("tablet")`
    display: block;
  `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;
