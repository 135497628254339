import React from "react";
import { media, Icon, color } from "@sencrop/ui-components";
import { WebText } from "@sencrop/ui-web";
import styled, { css } from "styled-components";
import LinkRenderer, { Link } from "./LinkRenderer";

type EventContainerProps = {
  event?: Link;
  multiple?: boolean;
  position: string;
};

const EventContainer = ({ event, multiple, position }: EventContainerProps) => {
  if (!event) {
    return null;
  }

  const isLink = event.url || event.ref || event.file || event.form;
  return (
    <LinkRenderer link={event}>
      <Container $position={multiple ? position : "only"}>
        <WebText semibold color="light">
          {event.label}
        </WebText>
        {isLink && (
          <IconContainer>
            <Icon name="chevron-right" />
          </IconContainer>
        )}
      </Container>
    </LinkRenderer>
  );
};

export default EventContainer;

type ContainerProps = {
  $position: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: space-between;
  p {
    width: fit-content;
    margin-right: 0.5rem;
  }
  ${(props) => css`
    ${props.$position === "second" &&
    css`
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    `};
    ${media.greaterThan("tablet")`
      padding: 0;
      margin-top: 12px;
      margin-bottom: 12px;
      ${
        props.$position === "first" &&
        css`
          justify-content: flex-end;
          padding-right: 1rem;
        `
      }
      ${
        props.$position === "second" &&
        css`
          justify-content: flex-start;
          padding-left: 1rem;
          border-top: none;
          border-left: 1px solid rgba(255, 255, 255, 0.5);
        `
      }
      ${
        props.$position === "only" &&
        css`
          justify-content: center;
          padding-right: 0;
          p {
            margin-right: 0.5rem;
          }
        `
      }
      p {
          margin-right: 1rem;
      }
    `}
  `};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${color("background-primary:10")};
`;
